<template>
    <div
        v-if="value"
        class="answers-variant-process"
    >
        <div v-if="singleSelect">
            <v-radio-group
                hide-details
                class="mt-0"
            >
                <v-radio
                    v-for="item in variants"
                    :key="item.id"
                    :label="item.value"
                    :value="item.value"
                    @change="() => onProcess(true, item.id)"
                />
            </v-radio-group>
        </div>
        <div v-else>
            <v-checkbox
                v-for="item in variants"
                :key="item.id"
                :label="item.value"
                hide-details
                @change="isChecked => onProcess(isChecked, item.id)"
            />
        </div>
    </div>
</template>

<script>
import questionsTypeBasePropsMixin from './mixins/questionsTypeBasePropsMixin'
import _ from 'lodash'
export default {
    name: 'AnswersVariantProcess',
    mixins: [questionsTypeBasePropsMixin],
    props: {
        singleSelect: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        variants() {
            return this.value.variants
        }
    },
    data: () => ({
        result: {
            reply: [],
            passed: false
        }
    }),
    methods: {
        onProcess(isChecked, id) {
            const correct = this.value.correct
            const result = this.result

            if(this.singleSelect) {
                result.reply = []
            }

            if(isChecked) {
                result.reply.push(id)
            } else {
                result.reply = [].concat(result.reply.filter(o => o !== id))
            }

            const replySize = result.reply.length
            let process = null

            if(replySize > 0) {
                result.passed = _.isEqual(correct, result.reply)

                process = {
                    options: this.value,
                    score: result.passed ? this.score : 0,
                    defaultScore: this.score,
                    ...result
                }
            }

            this.$emit('process', process)
        }
    }
}
</script>

<style lang=scss>

</style>
